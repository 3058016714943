import {
  addToCustom,
} from '../helpers';

const cookieConsentCountries = process.env.cookieConsentCountries;

export const getMultipleEventObjects = (eventObj, client_event_id) => {
  const {
    name,
    event_label,
    params = {},
  } = eventObj;

  if (name === 'view_item_list' && event_label.includes('|')) {
    const lists = event_label.split('|');
    return lists.map((list, index) => {
      const items = params.items.filter(item => item.list_name === list);
      const newEventObject = JSON.parse(JSON.stringify(eventObj));
      newEventObject.event_label = list;
      newEventObject.params.items = items;
      if (index === 0) {
        newEventObject.custom.client_event_id = client_event_id;
      }
      return newEventObject;
    })
  }
  eventObj.custom.client_event_id = client_event_id;
  return [eventObj];
};

const getListInfoFromItems = (items) => {
  if (items && items.length) {
    return {
      list_name: items[0].list_name,
      list_algorithm: items[0].algorithm,
      item_product_image_id: items[0].product_image_id,
    }
  }

  return {};
}

const getOrigin = (eventObj) => {
  const allowedPlatforms = ['kiosk', 'physical', 'app']
  const { site_platform } = eventObj;

  if (allowedPlatforms.includes(site_platform)) {
    return site_platform;
  }

  return 'web';
}

export const convertGAtoEpisod = (eventObj, { initialPageView = false, language }) => {
  const {
    page_location = window.location.href,
    event_action,
    name,
    event_category,
    custom: originalCustom = {},
    params
  } = eventObj;

  const { paramsItems, paramsEventTransaction, paramsCustom } = destructParams(params);
  const { customEventTransaction, custom } = destructCustom(originalCustom);
  const addedCustom = initialPageView ? addToCustom(!initialPageView, true) : {};
  eventObj.custom = Object.assign(addedCustom, custom, paramsCustom);

  const event_name = event_action || name || event_category;
  const eventTransaction = getEventTransaction(paramsEventTransaction, customEventTransaction, paramsItems);
  const noTransactionId = !eventTransaction.order_id;
  let transactionCustom = {};
  if (noTransactionId) {
    transactionCustom = eventTransaction;
  }

  return {
    currency_code: eventObj.custom.currency || eventObj.custom.local_currency_code,
    event_location: decodeURIComponent(page_location).trim(),
    event_origin: getOrigin(eventObj),
    event_origin_team: custom.team || null,
    event_trigger: custom.event_trigger,
    event_object: custom.event_object,
    logged_in: eventObj.custom.logged_in,
    event_name,
    event_details: getEventDetails(eventObj, paramsItems, language, event_name, transactionCustom),
    transaction: noTransactionId ? null : eventTransaction,
  }
}

const destructCustom = (custom) => {
  const {
    payment_method,
    order_type,
    delivery_method,
    delivery_time_slot,
    fulfilled_by,
    service_area_id,
    delivery_arrangement_id,
    checkout_capabilities,
    pick_up_point_location,
    product_vat,
    product_value_ex_vat,
    shipping_vat,
    services_vat,
    services_value_ex_vat,
    ...restCustom
  } = custom;

  const customEventTransaction = {
    payment_method,
    order_type,
    delivery_method,
    delivery_time: delivery_time_slot,
    fulfilled_by,
    service_area_id,
    delivery_arrangement_id,
    checkout_capabilities,
    pick_up_point_location,
    product_vat,
    product_value_ex_vat,
    shipping_vat,
    services_vat,
    services_value_ex_vat,
  };

  return { customEventTransaction, custom: { ...restCustom, checkout_capabilities, delivery_time: delivery_time_slot } };
}

const destructParams = (params) => {
  if (!params) {
    return {};
  }

  const { items, ...restParams } = params;

  const {
    shipping,
    tax,
    transaction_id,
    value,
    checkout_option,
    ...paramsCustom
  } = restParams;

  const paramsEventTransaction = {
    transaction_id,
    tax,
    value,
    shipping,
    checkout_option,
  }

  return { paramsItems: items, paramsEventTransaction, paramsCustom: { ...paramsCustom, checkout_option } };
}

const getEventTransaction = (paramsEventTransaction, customEventTransaction, items) => {
  const {
    transaction_id,
    tax,
    value,
    shipping,
    checkout_option,
  } = paramsEventTransaction ? paramsEventTransaction : {};

  const {
    payment_method,
    order_type,
    delivery_method,
    delivery_time,
    fulfilled_by,
    service_area_id,
    delivery_arrangement_id,
    checkout_capabilities,
    pick_up_point_location,
    quantity_unique_services,
    product_vat,
    product_value_ex_vat,
    shipping_vat,
    services_vat,
    services_value_ex_vat,
  } = customEventTransaction ? customEventTransaction : {};

  const getQuantity = (items) => {
    let quantity_items;
    let quantity_unique_items;

    if (items && items.length) {
      quantity_unique_items = items.length;
      quantity_items = items.reduce((accumulator, item) => {
        if (item.quantity) {
          return accumulator + item.quantity;
        }
        return accumulator;
      }, 0)

      return { quantity_items, quantity_unique_items };
    }
    return {};
  }

  const getLeaveAtDoorConsent = (checkout_capabilities) => {
    if (checkout_capabilities && checkout_capabilities.includes('authority_to_leave_true')) {
      return true;
    }

    if (checkout_capabilities && checkout_capabilities.includes('authority_to_leave_false')) {
      return false;
    }
  }

  const { quantity_items, quantity_unique_items } = getQuantity(items);

  return {
    order_id: transaction_id,
    quantity_items,
    quantity_unique_items,
    quantity_unique_services,
    total_vat: tax,
    total_value: value,
    product_vat,
    product_value_ex_vat,
    shipping_vat,
    shipping_value_ex_vat: shipping,
    services_vat,
    services_value_ex_vat,
    payment_method,
    order_type,
    delivery_method,
    delivery_express: checkout_option ? checkout_option.includes('express') : null,
    fulfilled_by,
    delivery_time,
    leave_at_door_consent: getLeaveAtDoorConsent(checkout_capabilities),
    pick_up_point_location,
    service_area_id,
    delivery_arrangement_id,
  }
}

const getEventItems = (items, nonEcommerce) => {
  const getBuyableOnline = (buyable, notBuyable) => {
    if (buyable) {
      return true;
    }
    if (notBuyable) {
      return false;
    }
  }

  if (items) {
    return items.map(
      ({
         id,
         price,
         quantity,
         store_availability,
         delivery_availability,
         collect_availability,
         assembly_availability,
         available_quantity,
         product_quantity,
         order_type_product,
         list_name,
         list_position,
         ratings_value,
         number_of_ratings,
         algorithm,
         item_number_event,
         item_type_event,
         commercial_label,
         sales_steering_tag,
         product_buyable_online,
         product_not_buyable_online,
         delivery_availability_hit,
         store_availability_hit,
         collect_availability_hit,
         assembly_availability_hit,
         ...custom
       }) => ({
        item_no: id,
        quantity: parseIntOrNull(quantity),
        price: parseFloatOrNull(price),
        availability_online: delivery_availability || delivery_availability_hit,
        availability_store: store_availability || store_availability_hit,
        availability_collect: collect_availability || collect_availability_hit,
        availability_assembly: assembly_availability || assembly_availability_hit,
        store_available_quantity: available_quantity,
        order_type: order_type_product,
        commercial_label,
        list_position,
        ratings_value,
        number_of_ratings: parseIntOrNull(number_of_ratings),
        sales_steering_tag,
        buyable_online: getBuyableOnline(product_buyable_online, product_not_buyable_online),
        referring_items: item_number_event ? [{
          item_no: item_number_event,
          type: item_type_event,
          // TODO: price
        }] : null,
        ...custom,
      })
    );
  }
  if (nonEcommerce) {
    const {
      item_number_event,
      item_type_event,
      product_quantity,
      delivery_availability_hit,
      collect_availability_hit,
      store_availability_hit,
      assembly_availability_hit,
      available_quantity,
      commercial_label,
      sales_steering_tag,
      product_image_id,
    } = nonEcommerce;

    if (!item_number_event) {
      return;
    }
    const item_numbers = item_number_event.split(',');
    const item_events = item_type_event ? item_type_event.split(',') : [];
    const item_quantities = product_quantity ? String(product_quantity).split(',') : [];

    return item_numbers.map((item_no, index) => ({
      item_no: item_no,
      quantity: parseIntOrNull(item_quantities[index]),
      type: item_events[index],
      availability_online: delivery_availability_hit,
      availability_store: store_availability_hit,
      availability_collect: collect_availability_hit,
      availability_assembly: assembly_availability_hit,
      store_available_quantity: available_quantity,
      commercial_label,
      sales_steering_tag,
      product_image_id,
    }))
  }
}

const getSpecificSearchHits = (searchHits, content) => {
  if (Number.isInteger(searchHits)) {
    return content ? null : searchHits;
  }

  if(!searchHits) {
    return null;
  }

  const specificSearchHits = searchHits.split(',');

  return content ? parseInt(specificSearchHits[1]) : parseInt(specificSearchHits[0]);
}

const parseFloatOrNull = (item) => {
  return item ? parseFloat(item) : null;
}

const parseIntOrNull = (item) => {
  return item ? parseInt(item) : null;
}

const getEventCategory = (eventName) => {
  const engagementEvents = [
    'view_item_list',
    'view_item',
    'select_content',
  ];

  if (engagementEvents.includes(eventName)) {
    return 'engagement';
  }

  const ecommerceEvents = [
    'checkout_progress',
    'add_to_cart',
    'remove_from_cart',
    'begin_checkout',
    'purchase',
  ]

  if (ecommerceEvents.includes(eventName)) {
    return 'ecommerce';
  }
}

const getEventDetails = (eventObj, items, language, eventName, transactionCustom) => {
  const mergedCustom = Object.assign({}, eventObj.custom, transactionCustom);
  const {
    team,
    event_trigger,
    event_object,
    stock_check_store_id,
    postal_code,
    search_type,
    internal_search_term,
    internal_search_hits,
    item_number_event,
    item_type_event,
    product_quantity,
    delivery_time,
    delivery_arrangement_id,
    checkout_id,
    appointment_time,
    delivery_availability_hit,
    collect_availability_hit,
    store_availability_hit,
    assembly_availability_hit,
    available_quantity,
    currency,
    navigation_type,
    navigation_link,
    navigation_timing,
    planner,
    planning_id,
    error_type,
    error_category,
    error_text,
    error_message,
    error_api,
    api_errors_preceding,
    cart_id,
    cart_quantity,
    cart_value,
    service_cart_value,
    list_id,
    list_value,
    shopping_list_quantity,
    technical_component,
    version,
    page_id,
    component_id,
    component_type,
    component_image_id,
    propensity,
    kiosk_id,
    kiosk_location,
    product_list_variant,
    algorithm,
    feed_tag_combinations,
    product_image_id,
    position,
    question,
    nps_comment,
    comment,
    nps_score,
    score,
    commercial_label,
    sales_steering_tag,
    checkout_type,
    consumer_name,
    quantity_items,
    quantity_unique_items,
    delivery_method,
    payment_method,
    delivery_cost,
    delivery_express,
    pick_up_point_location,
    order_type,
    order_type_detailed,
    services_available,
    removal_services_available,
    user_type,
    coupon,
    error_id,
    error_code,
    error_status_code,
    error_cause,
    additional_search_info,
    item_index,
    content_type,
    local_store,
    local_currency_code,
    ...custom
  } = mergedCustom;
  const {
    event_category,
    event_label,
    event_value,
    planner_name,
  } = eventObj;

  const { list_name, list_algorithm, item_product_image_id } = getListInfoFromItems(items);

  return {
    category: event_category || getEventCategory(eventName),
    label: event_label && event_label.toString(),
    location_language: language,
    delivery_time,
    delivery_arrangement_id,
    appointment_time,
    navigation_type,
    navigation_link,
    navigation_timing,
    error_type,
    error_category,
    error_public_message: error_text,
    error_backend_message: error_message,
    error_api,
    error_preceding_api: api_errors_preceding,
    shopping_cart_id: cart_id,
    shopping_cart_quantity: cart_quantity,
    shopping_cart_value: cart_value,
    shopping_cart_value_services: service_cart_value,
    shopping_list_id: list_id,
    shopping_list_quantity,
    shopping_list_value: list_value,
    search_term: internal_search_term,
    search_type: search_type,
    search_products_results: getSpecificSearchHits(internal_search_hits, false),
    search_content_results: getSpecificSearchHits(internal_search_hits, true),
    planner_name: planner || planner_name,
    planner_product_combination_id: planning_id,
    component_version: technical_component || version,
    component_page_id: page_id,
    component_id,
    component_type,
    component_propensity: propensity,
    component_image_id,
    kiosk_id,
    kiosk_location,
    list_type: product_list_variant,
    list_name,
    list_algorithm,
    list_filters: feed_tag_combinations,
    image_id: product_image_id || item_product_image_id,
    image_position: position,
    feedback_question: question,
    feedback_comment: nps_comment || comment,
    feedback_score: nps_score || score,
    checkout_id,
    checkout_type,
    checkout_consumer_name: consumer_name,
    checkout_quantity_items: quantity_items,
    checkout_quantity_unique_items: quantity_unique_items,
    checkout_delivery_time: delivery_time,
    checkout_delivery_arrangement_id: delivery_arrangement_id,
    checkout_delivery_method: delivery_method,
    checkout_payment_method: payment_method,
    checkout_delivery_cost: delivery_cost,
    checkout_delivery_express: delivery_express,
    checkout_pick_up_point_location: pick_up_point_location,
    checkout_order_type: order_type,
    checkout_order_type_detailed: order_type_detailed,
    checkout_services_available: services_available,
    checkout_removal_services_available: removal_services_available,
    checkout_user_type: user_type,
    checkout_local_store: local_store,
    checkout_coupon: coupon,
    error_id,
    error_code,
    error_status_code,
    error_cause,
    additional_search_info,
    item_index,
    product_list_variant,
    content_type,
    items: getEventItems(
      items,
      {
        item_number_event,
        item_type_event,
        product_quantity,
        delivery_availability_hit,
        collect_availability_hit,
        store_availability_hit,
        assembly_availability_hit,
        available_quantity,
        commercial_label,
        sales_steering_tag,
        product_image_id,
      }),
    ...custom,
    event_value,
  }
}

export const isCookieConsentCountry = (country) => {
  return cookieConsentCountries.includes(country);
}

export const getCookieConsent = () => {
  if (window.ikea && window.ikea.cookieConsent && window.ikea.cookieConsent.hasConsent) {
    return {
      analytics: window.ikea.cookieConsent.hasConsent(2),
      personalisation: window.ikea.cookieConsent.hasConsent(3),
      marketing: window.ikea.cookieConsent.hasConsent(4),
    }
  }

  return null;
}

export const getPostalCode = async () => {
  const postalCodeResponse = window.ikea && window.ikea.postalCode
    ? await window.ikea.postalCode.get()
    : null;

  return postalCodeResponse ? postalCodeResponse.postalCode : null;
}

export const getStoreId = async () => {
  return window.ikea && window.ikea.store
    ? await window.ikea.store.get()
    : null;
}
